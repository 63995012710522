@import '~/bb/ui/Styles/variables';

.normal {
    min-height: var(--navbar-height);
    padding: 14px;
    background-color: var(--color-primary-white);

    .logoWrapper {
        width: 100%;
        max-width: max-content;
    }
}

.fixed {
    position: fixed;
    top: 0;
    z-index: var(--z-index-nav);
    display: flex;
    width: 100%;
    transition: transform 0.3s ease;
}

.slim {
    position: sticky;
    top: 0;
    left: 0;
    z-index: var(--z-index-nav);

    .container {
        height: var(--navbar-height-slim);
        padding: 0 20px;
    }

    .slimLogo {
        display: inline-block;
        width: 120px;
        line-height: 0;

        svg {
            overflow: visible;
        }
    }
}

.black {
    background-color: var(--color-primary-black);
}

.logo {
    display: flex;
    width: 120px;
    padding: 10px;
    overflow: visible;
    fill: var(--color-primary-black);

    @media screen and (width >=576px) {
        width: 180px;
    }
}

.whiteLogo {
    fill: var(--color-primary-white);
}

.sideMenu {
    li {
        margin-top: var(--distance-extra-small);
    }

    ul {
        padding-inline-start: 0;
        list-style: none;

        // nested menu
        ul {
            padding-inline-start: 1rem;
        }
    }

    // override Button anchor styling to make it  more compact
    a,
    button {
        padding: 0;
    }
}

.horizontal {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;

    ul {
        display: inline-flex;
        justify-content: space-evenly;
        width: 100%;
        max-width: 650px;
        padding-inline-start: 0;
    }

    li {
        display: inline-block;
        margin: 0 10px;
    }
}

.show {
    display: flex;
}

.topActions {
    display: none;
    flex-direction: row;
    width: 100%;
    white-space: nowrap;

    & > * {
        margin-left: 0.75rem;
    }

    & > *:first-child {
        margin-left: 0;
    }

    @media screen and (min-width: $screen-size-xl) {
        display: flex;
    }
}

.openMenuButtonContainer {
    display: flex;

    // this is to get the center menu centered automagically
    // if the logo and menu button have equal width, then
    // the menu will be centered
    justify-content: flex-end;
    width: 72px;

    @media screen and (min-width: $screen-size-xl) {
        display: none;
    }

    &.show {
        display: flex;
    }
}

.mobileCta {
    text-align: center;
    visibility: hidden;

    @media screen and (width >=360px) {
        margin-left: auto !important;
        visibility: visible;
    }

    @media screen and (min-width: $screen-size-xl) {
        display: none !important;
    }
}

.desktopNavbar {
    display: none;

    @media screen and (min-width: $screen-size-xl) {
        display: block;
    }
}
