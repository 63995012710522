@import '~/bb/ui/Styles/variables';

@mixin toRem($property, $value) {
    #{$property}: ($value / $font-base-size) + rem;
}

.root {
    line-height: 140%;
}

.upperCase {
    text-transform: uppercase;
}

.bold {
    font-weight: bold;
}

.center {
    text-align: center;
}

.underline {
    text-decoration: underline;
}

.block {
    display: block;
}

.lineHeightSlim {
    line-height: 100%;
}

.lineHeightAverage {
    line-height: 120%;
}

.lineHeightBig {
    line-height: 140%;
}

.body1 {
    @include toRem(font-size, 16);
}

.body2 {
    @include toRem(font-size, 14);
}

.body3 {
    @include toRem(font-size, 12);
}

.mega {
    @include toRem(font-size, 96);

    &.responsive {
        @media (width < $screen-size-sm) {
            @include toRem(font-size, 48);
        }
    }
}

.huge {
    @include toRem(font-size, 72);

    &.responsive {
        @media (width < $screen-size-sm) {
            @include toRem(font-size, 44);
        }
    }
}

.h1 {
    @include toRem(font-size, 64);

    &.responsive {
        @media (width < $screen-size-sm) {
            @include toRem(font-size, 36);
        }
    }
}

.h2 {
    @include toRem(font-size, 48);

    &.responsive {
        @media (width < $screen-size-sm) {
            @include toRem(font-size, 32);
        }
    }
}

.h3 {
    @include toRem(font-size, 42);

    &.responsive {
        @media (width < $screen-size-sm) {
            @include toRem(font-size, 28);
        }
    }
}

.h4 {
    @include toRem(font-size, 32);

    &.responsive {
        @media (width < $screen-size-sm) {
            @include toRem(font-size, 24);
        }
    }
}

.h5 {
    @include toRem(font-size, 26);

    &.responsive {
        @media (width < $screen-size-sm) {
            @include toRem(font-size, 20);
        }
    }
}

.h6 {
    @include toRem(font-size, 22);

    &.responsive {
        @media (width < $screen-size-sm) {
            @include toRem(font-size, 20);
        }
    }
}

.badge {
    @include toRem(font-size, 16);
}

.breakWord {
    word-break: break-word;
}

.color {
    color: var(--typography-color);
}
