@import '../Styles/variables';

.list {
    display: flex;
    flex: 1;
    padding-inline-start: 0;
    list-style-type: none;
}

.listItem {
    display: list-item;
}

.disc {
    padding-inline-start: 24px;
    list-style-type: disc;
}

.decimal {
    padding-inline-start: 24px;
    list-style-type: decimal;
}

.icon {
    margin-right: 5px;
}

.row {
    flex-direction: row;
}

.column {
    flex-direction: column;
}

.right {
    border-right: 2px solid var(--color-gray-01);
}

.left {
    border-left: 2px solid var(--color-gray-01);
}

.bottom {
    border-bottom: 2px solid var(--color-gray-01);
}

.top {
    border-top: 2px solid var(--color-gray-01);
}

.fluid {
    width: 100%;
}
